<template>
  <div id="regimes" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">LISTA DE REGÍMENES</v-row>
      <v-row no-gutters justify="end" class="mr-12">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addRegime" id="nuevoRegimen">
              <button class="breakSearch botonAmarillo">Alta de Regímen</button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="regimes"
            :items-per-page="5"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            no-results-text="No se encontraron incidencias"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.nombreRegimen }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Editar item-->
    <v-dialog v-model="edit" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Edición de datos</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-5">
            <div class="form-group">
              <label for="regime" class="mr-8">Regímen</label>
              <input
                type="text"
                name="regime"
                id="regime"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="regime"
                required
                maxlength="20"
                @keypress="alfaNumerico($event)"
              />
              <br />
              <br />
              <label v-if="vacio" style="color: red"
                >*Debe especificar el nombre del Regímen</label
              >
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacion()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Tipo de Riesgo</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Regímen",
          align: "center",
          value: "nombreRegimen",
        },
        {
          text: "Editar  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      regimes: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      regime: "",
      vacio: false,
    };
  },
  methods: {
    listar() {
      this.show = true;
      axios
        .get(Server + "/regimen/", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.regimes = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    editItem(item) {
      console.log("El id es: " + item.id);
      this.id = item.id;
      this.regime = item.nombreRegimen;
      this.edit = true;
    },
    validacion: function () {
      this.errors = [];
      if (this.regime == "" || this.regime == " ") {
        this.errors.push("Se debe especificar el nombre del regímen.");
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },
    editar() {
      /*console.log("El id para la api es" + this.id);
      console.log("Ingreso a editar" + this.regime);*/
      this.respuesta = "";
      this.show = true;
      axios
        .put(
          Server + "/regimen/" + this.id,
          {
            id: this.id,
            nombreRegimen: this.regime,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.regime = "";
          this.respuesta = "El elemento fue actualizado con éxito";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.regime = "";
            this.respuesta = "El elemento no fue actualizado";
            this.confirmation = false;
          }
        });
    },
    /*
      temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
      guarda solo el id del dato
    */
    temporal(item) {
      console.log("El id es: " + item.id);
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el elemento " + item.nombreRegimen + " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      axios
        .delete(Server + "/regimen/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = false;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aux() {
      this.edit = false;
      this.regime = "";
      this.vacio = false;
    },
  },
  created() {
    this.listar();
  },
};
</script>